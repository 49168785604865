:root {
  /* --e-primary-color: #0d6efd; */
  /* --e-primary-color: #6750a4; */
  /* --e-white-color: #f7f7f7; */
  --e-primary-color: #317ab9;
  --e-white-color: #fff;
  --light-gray-color: lightgray;
}

@font-face {
  font-family: Calibri !important;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY6w&skey=a1029226f80653a8&v=v15)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,
body {
  height: 100%;
  margin: 0px;
  font-family: Calibri !important;
}

.e-tab {
  .e-tab-header {
    .e-toolbar-item {
      .e-tab-text {
        font-family: Calibri !important;
      }
      &.e-active {
        .e-tab-text {
          font-family: Calibri !important;
        }
      }
    }
  }
}

.e-control,
.e-css {
  font-family: Calibri !important;
}

.e-grid .e-table{   
  font-family: Calibri !important;
}

.e-grid .e-rowcell {
  font-size: 12px;
  font-family: Calibri !important;
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  font-family: Calibri !important;
}

.e-radio-wrapper .e-label,
.e-css.e-radio-wrapper .e-label {
  font-family: Calibri !important;
}

.e-radio {
  & + label {
    .e-label {
      font-family: Calibri !important;
    }
  }
}

.e-dropdown-popup {
  ul {
    font-family: Calibri !important;
  }
}

#sb-content {
  .e-spreadsheet {
    .e-tab {
      .e-tab-text {
        display: inherit;
      }
    }
  }
}

.e-spreadsheet {
  .e-cell {
    .e-radio-wrapper {
      margin: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

/* newly added */
/* Media Queries based on height */
/* Small height devices (less than 600px) */
@media (max-height: 599px) {
  .spreadsheet-component .e-sheet-panel {
    font-size: 15px !important;
    height: calc(100vh - 280px) !important;
  }

}

/* Medium height devices (600px to 899px) */
@media (min-height: 600px) and (max-height: 899px) {
  .spreadsheet-component .e-sheet-panel {
    font-size: 15px !important;
    height: calc(100vh - 260px) !important;
  }
 
}

/* Large height devices (900px and above) */
@media (min-height: 900px) {
  .spreadsheet-component .e-sheet-panel {
    font-size: 15px !important;
    min-height: calc(100vh - 240px) !important;
  }
}

// every modal header
.e-dialog {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.e-dlg-header-content .e-dlg-closeicon-btn .e-icon-dlg-close {
  color: var(--e-primary-color);
}

.e-dialog .e-dlg-header-content {
  border-bottom: 1px solid var(--light-gray-color);
  // margin-bottom: 16px;
  margin-bottom: 14px;
  border-top: 14px solid var(--e-primary-color) !important;
}

.e-dlg-header-content {
  border-top: 14px solid var(--e-primary-color) !important;
}

.e-dialog .e-footer-content {
  border-top: 1px solid var(--light-gray-color);
  // margin-top: 10px;
}

.e-sort-dialog .e-sort-listwrapper .text {
  font-size: 14px;
}

.e-dialog .e-dlg-content {
  height: 100% !important;
}

#dialog9_dialog-content .e-input-group,
.e-input-group.e-control-wrapper {
  margin-top: 20px !important;
}

#dialog10_dialog-content .e-input-group,
.e-input-group.e-control-wrapper {
  margin-top: 20px !important;
}

#ddlelement .e-input-group,
.e-input-group.e-control-wrapper {
  margin-top: 0px !important;
}

.policies-list-content {
  display: flex;
  gap: 10px;
}

//
.e-customsort-dlg .e-popup-open {
  height: 500px !important;
}

.e-btn.e-flat.e-success,
.e-css.e-btn.e-flat.e-success {
  background: var(--e-primary-color);
  border-color: #265f91;
  color: #fff;
}

.e-btn.e-flat.e-success:hover,
.e-css.e-btn.e-flat.e-success:hover {
  background: var(--e-primary-color);
  border-color: #265f91;
  color: #fff;
}

.e-btn.e-flat.e-success:focus,
.e-css.e-btn.e-flat.e-success:focus {
  background: var(--e-primary-color);
  border-color: #265f91;
  color: #fff;
}

#dialog .e-dlg-header-content + .e-dlg-content {
//  padding-bottom: 31px;
 // padding-bottom: 50px;
  padding-top: 0px;
}

#dialog .e-dlg-content{
  overflow: unset !important;
  overflow-x: unset !important
}

#refreshbtn{
height: 35px;
}

#dialog1 .e-dlg-content{
  overflow: unset !important;
  overflow-x: unset !important
}

#dialog1 .e-dlg-header-content + .e-dlg-content {
  // padding-bottom: 31px;
  // padding-bottom: 50px;
  padding-top: 0px;
}

.e-dialog .e-dlg-header-content+.e-dlg-content{
  padding-top : 0px !important;
}
//
.e-dlg-header-content {
  background-image: url(../assets/Icons/baystream.ico);
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 12px;
  background-size: 30px;
}

.e-dialog .e-dlg-header {
  margin-left: 37px;
}

.e-spreadsheet-function-dlg.e-dialog .e-dlg-content .e-category-content {
  font-size: 15px !important;
}

.e-spreadsheet .e-protect-dlg.e-dialog {
  width: 340px;
}

//
.e-btn.e-primary:hover,
.e-css.e-btn.e-primary:hover {
  background: var(--e-primary-color);
  border-color: var(--e-primary-color);
  box-shadow: none;
  color: #fff;
}

.e-dlg-content .e-password-dialog .e-password-content .e-header {
  font-size: 15px !important;
  font-family: Calibri;
}

.e-dlg-content .e-open-dialog .e-open-head {
  margin-bottom: -10px;
}

.e-multi-select-wrapper .e-chips>.e-chipcontent{
  font-family: Calibri !important;
}

.e-spreadsheet .e-protect-dlg.e-dialog .e-sheet-password-content .e-header {
  margin-bottom: 0px;
}

//
.label.e-float-text,
.e-float-input label.e-float-text,
.e-float-input.e-control-wrapper label.e-float-text {
  font-family: Calibri;
  text-align: left;
}

.e-popup {
  border-color: var(--e-white-color) !important;
  border: 0px !important;
  border-radius: 8px !important;
}

// arrange
#arrange-dialog {
width: 470px ;
max-width: 1000px;
 // left: 100px!important;
  // top: 100px !important;
  z-index: 111 !important;
}

#arrange-dialog .arrange-top-button {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

#arrange-dialog #reorder .e-headercelldiv {
  cursor: pointer;
  width: max-content;
}

#reorder input {
  border: 1px solid #888;
  color: var(--e-white-color);
  cursor: pointer;
  font-size: 10pt;
  font-weight: normal;
  border-radius: 8px;
  margin-left: 25px;
  height: 15px;
  width: 15px;
}

.alertDialog {
  color: red;
}

/* Target the first th in each header row */
.e-columnheader > th:first-child {
  width: 90px; /* Set your desired width */
}

/* Target the first th in each header row and apply text alignment */
.e-columnheader > th:first-child .e-headercelldiv {
  text-align: center; /* Center-aligns text */
  margin-left: 1px;
}

.e-columnheader > th:nth-child(2) .e-headercelldiv {
  text-align: center; /* Center-aligns text */
  margin-left: -7px;
}

/* Target the first td in each row */
.e-row > td:first-child {
  width: 90px; /* Set your desired width */
}

/* Custom spinner styles when save a file */
.e-custom-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Full-screen overlay */
.e-ss-custom-loader {
  // border: 8px solid rgba(255, 255, 255, 0.3);
  border: 8px solid #fff;
  border-top: 8px solid var(--e-primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
