.server-connection-main {
  margin-top: 10em;
  margin-left: auto;
  margin-right: auto;
  max-width: 820px !important;
  height: auto;
  position: relative;
  border-top: 12px solid var(--e-primary-color);
  font-size: 100%;
  text-align: center;
  background-color: var(--e-white-color);
  border-radius: 15px;
  cursor: auto;
  padding: 34px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  text-align: left !important;

  .form--user__icon {
    border-radius: 50%;
    height: 100px;
    z-index: 9;
    top: -50px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    background: var(--e-white-color);
    width: 100px;
    color: #fff;
    border: 1px solid var(--e-primary-color);
  }

  .text-m-files {
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 24px;
  }
  
  .connect-to-m-file {
    color: var(--e-primary-color);
    font-style: italic;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
  }

  .container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container-box {
    .server-address-connect {
      height: 100%;
    }
    .vault-connect {
      height: 100%;
      margin-bottom: auto;
    }

    .test-connections-div {
      display: flex;
      justify-content: center;
      align-items: end;
      gap: 10px;
    }

    #btn-test-connection {
      width: 40px;
      height: 35px;
      cursor: pointer;
      border: 1px solid black;
      border: transparent;
    }

    .btn-open-document {
      // cursor: auto;
      cursor: pointer;
      width: 350px;
      height: 40px;
      border-radius: 50px;
      border: 1px solid var(--e-primary-color);
    }

    .btn-open-document-1 {
      // cursor: auto;
      cursor: pointer;
      position: relative;
      bottom: -150px;
      width: 350px;
      height: 40px;
      border-radius: 50px;
      border: 1px solid var(--e-primary-color);
    }
    
  }
}

.connect-login-footer{
  #info{
    color: red;
    font-size: 14px;
    margin-left: 75px;
    margin-top: 10px;
  }
}
