.default-appbar-container {
  .nav-container {
    .baystream-logo {
      // background-image: url(../assets/Icons/baystream.ico);
      background-repeat: no-repeat;
      background-position: left;
      position: relative;
      margin-right: 10px;
      object-fit: cover;
    }

    .regular {
      font-family: "Calibri" !important;
      color: var(--e-white-color) !important;
      font-size: 19px;
      // cursor: pointer;
    }

    .nav-file-name{
      color: yellow;
      margin-left: 50px;
    }
  }

  .dropdown-btn {
    width: 250px;
    .e-btn,
    .e-css.e-btn {
      background: transparent !important;
      color: white !important;
    }
  }

  .nav-right-buttons {
    display: flex;
    gap: 10px;
    .recent-files {
      width: 300px;
      display: flex;
      align-items: center;
      gap: 10px;

      .recent-files-text {
        width: 120px;
        font-size: 16px;
      }
    }
  }
}
