.login-container {
  .login-form {
    margin-top: 5em;
    margin-left: auto;
    margin-right: auto;
    //   width: 420px;
    //   height: 470px;
    max-width: 420px;
    position: relative;
    border-top: 12px solid var(--e-primary-color);
    font-size: 100%;
    text-align: center;
    background-color: var(--e-white-color);
    cursor: auto;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    .login-logo {
      margin-top: 40px;
      .login-title {
        color: var(--e-primary-color);
        font-weight: 550;
      }
      .login-img {
        object-fit: cover;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .login-button {
      .login-btn {
        width: 280px;
        // height: 33px;
        background: var(--e-primary-color);
        border: 1px solid #888;
        color: white;
        cursor: pointer;
        font-family: Calibri;
        font-size: 12pt;
        font-weight: normal;
        border-radius: 8px;
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }
}

/* Custom spinner styles when save a file */
.e-custom-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Full-screen overlay */
.e-ss-custom-loader {
  // border: 8px solid rgba(255, 255, 255, 0.3);
  border: 8px solid #fff;
  border-top: 8px solid var(--e-primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}